const dashboardTypes = {
  PROPERTY_ADD_START: "PROPERTY_ADD_START",
  PROPERTY_ADD_SUCCESS: "PROPERTY_ADD_SUCCESS",
  PROPERTY_ADD_FAILURE: "PROPERTY_ADD_FAILURE",
  PROPERTY_ADD_RESET: "PROPERTY_ADD_RESET",
  PROPERTY_ADD_CHANGED: "PROPERTY_ADD_CHANGED",

  DASHBOARD_LIST_START: "DASHBOARD_LIST_START",
  DASHBOARD_LIST_SUCCESS: "DASHBOARD_LIST_SUCCESS",
  DASHBOARD_LIST_FAILURE: "DASHBOARD_LIST_FAILURE",
  DASHBOARD_LIST_RESET: "DASHBOARD_LIST_RESET",
  DASHBOARD_LIST_CHANGED: "DASHBOARD_LIST_CHANGED",
};
export default dashboardTypes;
