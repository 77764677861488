const loginTypes = {
  USER_LOGIN_START: "USER_LOGIN_START",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  USER_LOGIN_RESET: "USER_LOGIN_RESET",
  USER_LOGIN_CHANGED: "USER_LOGIN_CHANGED",

  SIGN_IN_LOGOUT: "SING_IN_LOGOUT",
};
export default loginTypes;
